import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './Viewtestimonial.css'

function ViewTestimonial() {
  const [testimonials, setTestimonials] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({ type: '', text: '' });  // Initialize as object
  const [currentTestimonial, setCurrentTestimonial] = useState(null); 
  const [formData, setFormData] = useState({
    clientname: '',
    imageurl: '',
    profession: '',
    testimonial: '',
  });

  // Fetch testimonials
  const fetchTestimonials = async () => {
    try {
      const response = await axios.get('https://api.shreedigimarketing.in/api/testimonials');
      setTestimonials(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      setLoading(false);
    }
  };

  // Handle Delete
  const handleDelete = async (id) => {
    try {
      const response = await axios.post('https://api.shreedigimarketing.in/api/deletetestimonial', {
        tid: id,
      });
      console.log(response.data.message);
      fetchTestimonials(); 
      alert('Testimonial Deleted successfully!');
    } catch (error) {
      console.error('Error deleting testimonial:', error);
    }
  };

  // Handle Update
  const handleUpdate = (testimonial) => {
    setCurrentTestimonial(testimonial);
    setFormData({
      clientname: testimonial.clientname,
      imageurl: testimonial.imageurl,
      profession: testimonial.profession,
      testimonial: testimonial.testimonial,
    });
  };

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.clientname || !formData.profession || !formData.testimonial) {
      setMessage({ type: 'error', text: 'All fields are required.' });
      return;
    }
    try {
      const response = await axios.post('https://api.shreedigimarketing.in/api/updatetestimonial', {
        ...formData,
        tid: currentTestimonial.id,
      });
      setMessage({ type: 'success', text: response.data.message });
      fetchTestimonials();
      setCurrentTestimonial(null);
      alert('Testimonial Updated successfully!');

    } catch (error) {
      setMessage({ type: 'error', text: 'Error updating testimonial' });
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  if (loading) {
    return <div>Loading testimonials...</div>;
  }

  return (
    <div id="wrapper">
       < body className="bg-theme bg-theme1" style={{height:'auto'}}>
      <div id="pageloader-overlay" className="visible incoming">
        <div className="loader-wrapper-outer">
          <div className="loader-wrapper-inner">
            <div className="loader"></div>
          </div>
        </div>
      </div>

      <div id="wrapper">
        <div className="clearfix"></div>

        <div className="content-wrapper" >
        <div className="container mt-4">
          <h2 className="text-center">Testimonials</h2>
          <div className="row">
            {testimonials.map((testimonial) => (
              <div className="col-md-4" key={testimonial.id}>
                <div className="card mb-4">
                  <img
                    src={`https://api.shreedigimarketing.in/${testimonial.imageurl}`}
                    className="card-img-top"
                    alt={`${testimonial.clientname}'s Testimonial`}
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{testimonial.clientname}</h5>
                    <h6 className="card-subtitle mb-2 text-muted" style={{ color: 'white' }}>
                      {testimonial.profession}
                    </h6>
                    <p className="card-text">{testimonial.testimonial}</p>
                    <div className="d-flex justify-content-between">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(testimonial.id)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleUpdate(testimonial)}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Update Modal */}
      {/* Update Modal */}
{currentTestimonial && (
  <div
    className="modal"
    style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    onClick={() => setCurrentTestimonial(null)} // Close modal on overlay click
  >
    <div
      className="modal-dialog modal-lg modal-dialog-centered"
      onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside it
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Update Testimonial</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setCurrentTestimonial(null)}
          ></button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Client Name</label>
              <input
                type="text"
                className="form-control"
                name="clientname"
                value={formData.clientname}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                name="imageurl"
                value={formData.imageurl}
                onChange={handleChange}
              />
              {formData.imageurl && (
                <div className="mt-2">
                  <img src={formData.imageurl} alt="Preview" style={{ width: '100px', height: 'auto' }} />
                </div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Profession</label>
              <input
                type="text"
                className="form-control"
                name="profession"
                value={formData.profession}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Testimonial</label>
              <textarea
                className="form-control"
                name="testimonial"
                value={formData.testimonial}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-success">
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setCurrentTestimonial(null)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
)}

       

        

      
      </div>
    </body>
    </div>
  );
}

export default ViewTestimonial;
