import React,{useEffect, useState} from 'react'
import dg from './images/logo (3).png'
import icon from './images/ic3.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setAuth } from './Redux/actions';


function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [user, setUser] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios
        .get('https://api.shreedigimarketing.in/api/currentuser', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log('API Response:', response.data);
          if (response.data && response.data.username) {
            setUser(response.data); // Set the entire response if needed
          } else {
            console.error('User data is missing in the response.');
          }
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []);  
  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
      try {
          await axios.post('https://api.shreedigimarketing.in/api/logout'); // Call your logout API
          localStorage.removeItem('token');
          dispatch(setAuth(false));
          navigate('/'); // Redirect to login page
      } catch (error) {
          console.error('Logout failed:', error);
      }
  };
  return (
    <div>

<div id="sidebar-wrapper" data-simplebar="" data-simplebar-auto-hide="true">
     <div className="brand-logo">
      <a href="/">
       <img src={dg} className="logo-icon" alt="logo icon"/>
       <h5 className="logo-text">Shree Admin</h5>
     </a>
   </div>
   <ul className="sidebar-menu do-nicescrol">
      <li className="sidebar-header">MAIN NAVIGATION</li>
      <li>
        <a href="/home">
          <i className="zmdi zmdi-view-dashboard"></i> <span>Dashboard</span>
        </a>
      </li>
      <li>
        <a href="/profile">
          <i className="zmdi zmdi-face"></i> <span>Profile</span>
        </a>
      </li>

      <li>
        <a href="/" >
          <i className="zmdi zmdi-lock"></i> <span>Login</span>
        </a>
      </li>

       <li>
        <a href="/register" >
          <i className="zmdi zmdi-account-circle"></i> <span>Registration</span>
        </a>
      </li>
      <li>
        <a href="/testimonial" >
          <i className="zmdi zmdi-account-circle"></i> <span>Add Testinomial</span>
        </a>
      </li>
      <li>
        <a href="/viewtestimonials" >
          <i className="zmdi zmdi-eye"></i> <span>View Testinomial</span>
        </a>
      </li>

     
    </ul>
   
   </div>
  
<header className="topbar-nav">
 <nav className="navbar navbar-expand fixed-top">
  <ul className="navbar-nav mr-auto align-items-center">
    
  </ul>
     
  <ul className="navbar-nav align-items-center right-nav-link">
    <li className="nav-item dropdown-lg">
      <a className="nav-link dropdown-toggle dropdown-toggle-nocaret waves-effect" data-toggle="dropdown" href="javascript:void();">
      <i className="fa fa-envelope-open-o"></i></a>
    </li>
    <li className="nav-item dropdown-lg">
      <a className="nav-link dropdown-toggle dropdown-toggle-nocaret waves-effect" data-toggle="dropdown" href="javascript:void();">
      <i className="fa fa-bell-o"></i></a>
    </li>
   
    <li className="nav-item">
      <a
        className="nav-link dropdown-toggle dropdown-toggle-nocaret"
        href="#"
        onClick={toggleDropdown}
      >
        <span className="user-profile">
          <img
            src={icon}
            className="img-circle"
            alt="user avatar"
            style={{ width: '40px', height: '40px', objectFit: 'cover' }}
          />
        </span>
      </a>
      {dropdownOpen && (
        <ul className="dropdown-menu dropdown-menu-right" style={{ display: 'block' }}>
          <li className="dropdown-item user-details">
            <a href="javascript:void(0);" className="d-flex align-items-center">
              <div className="media">
                <div className="avatar">
                  <img
                    className="align-self-start mr-3"
                    src={dg}
                    alt="user avatar"
                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                  />
                </div>
                <div className="media-body">
                          <h6 className="mt-2 user-title">{user?.username || 'Guest'}</h6>
                          <p className="user-subtitle">{user?.email || 'No Email'}</p>
                        </div>
              </div>
            </a>
          </li>
          <li className="dropdown-divider"></li>
          <li className="dropdown-item">
          <a href="/profile">
            <i className="icon-user mr-2"></i> Profile
            </a>
          </li>
          <li className="dropdown-divider"></li>
          <li className="dropdown-item">
            <a onClick={handleLogout} >
            <i className="icon-power mr-2"></i> Logout
            </a>
          </li>
        </ul>
      )}
    </li>
  </ul>
</nav>
</header>
    </div>
  )
}

export default Header