import React, { useState } from "react";
import logo from './images/logo (3).png'

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.shreedigimarketing.in/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: name, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);
        // Optionally, redirect the user to the login page
        // window.location.href = "/login";
      } else {
        setMessage(data.message || "Registration failed");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <div>
   < body className="bg-theme bg-theme1">
      <div id="pageloader-overlay" className="visible incoming">
        <div className="loader-wrapper-outer">
          <div className="loader-wrapper-inner">
            <div className="loader"></div>
          </div>
        </div>
      </div>

      <div id="wrapper">
        <div className="clearfix"></div>

        <div className="content-wrapper" style={{ marginLeft: '70vh' }}>
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-6">
              <div className="card card-authentication1 mx-auto my-4">
          <div className="card-body">
            <div className="card-content p-2">
              <div className="text-center">
              <img src={logo} alt="logo-icon" style={{height:'16vh',}}/>
              </div>
              <div className="card-title text-uppercase text-center py-3">
                Sign Up
              </div>
              {message && (
                <div className={`alert ${message.includes("success") ? "alert-success" : "alert-danger"}`} role="alert">
                  {message}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputName" className="sr-only">
                    Name
                  </label>
                  <div className="position-relative has-icon-right">
                    <input
                      type="text"
                      id="exampleInputName"
                      className="form-control input-shadow"
                      placeholder="Enter Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <div className="form-control-position">
                      <i className="icon-user"></i>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmailId" className="sr-only">
                    Email ID
                  </label>
                  <div className="position-relative has-icon-right">
                    <input
                      type="email"
                      id="exampleInputEmailId"
                      className="form-control input-shadow"
                      placeholder="Enter Your Email ID"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <div className="form-control-position">
                      <i className="icon-envelope-open"></i>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword" className="sr-only">
                    Password
                  </label>
                  <div className="position-relative has-icon-right">
                    <input
                      type="password"
                      id="exampleInputPassword"
                      className="form-control input-shadow"
                      placeholder="Choose Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <div className="form-control-position">
                      <i className="icon-lock"></i>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="icheck-material-white">
                    <input type="checkbox" id="user-checkbox" defaultChecked />
                    <label htmlFor="user-checkbox">
                      I Agree With Terms & Conditions
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-light btn-block waves-effect waves-light"
                >
                  Sign Up
                </button>
                <div className="text-center mt-3">Sign Up With</div>
                <div className="form-row mt-4">
                  <div className="form-group mb-0 col-6">
                    <button type="button" className="btn btn-light btn-block">
                      <i className="fa fa-facebook-square"></i> Facebook
                    </button>
                  </div>
                  <div className="form-group mb-0 col-6 text-right">
                    <button type="button" className="btn btn-light btn-block">
                      <i className="fa fa-twitter-square"></i> Twitter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="card-footer text-center py-3">
            <p className="text-warning mb-0">
              Already have an account? <a href="login.html">Sign In here</a>
            </p>
          </div>
        </div>

              </div>
            </div>
            <div className="overlay toggle-menu"></div>
          </div>
        </div>

        <a href="javaScript:void();" className="back-to-top">
          <i className="fa fa-angle-double-up"></i>
        </a>

        <footer className="footer">
          <div className="container">
          <a href='https://shreedigimarketing.in/'>
        Copyright © 2024 Shree Digital Marketing Agency
        </a>
          </div>
        </footer>

      
      </div>
    </body>
  </div>
    
  );
}

export default Register;
