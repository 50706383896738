import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode"; // Correct for the latest version
// Correct for the latest version
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import Profile from "./Profile";
import Testinomial from "./Testinomial";
import Header from "./Header";
import { Outlet } from "react-router-dom"; 
import ViewTestimonial from './ViewTestinomial'

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Toggle function for theme
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  console.log("isAuthenticated:", isAuthenticated); // Debugging step

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decoded.exp < currentTime) {
        console.log("Token has expired");
        // Handle token expiration, maybe log the user out or refresh the token
      } else {
        console.log("Token is valid");
        // Continue with the authenticated user flow
      }
    }
  }, []);

  // Wrapper component for Header
  const LayoutWithHeader = () => (
    <>
      <Header />
      <Outlet />
    </>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {isAuthenticated ? (
          <Route path="/" element={<LayoutWithHeader />}>
            <Route path="/home" element={<Home />} />
            <Route path="/testimonial" element={<Testinomial />} />
            <Route path="/viewtestimonials" element={<ViewTestimonial />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </Router>
  );
};

export default App;
