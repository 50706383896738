import React, { useState } from 'react';
import axios from 'axios';

function Testinomial() {
  const [formData, setFormData] = useState({
    clientname: '',
    profession: '',
    testimonial: '',
    image: null,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('clientname', formData.clientname);
    data.append('profession', formData.profession);
    data.append('testimonial', formData.testimonial);
    data.append('image', formData.image);
  
    try {
      const response = await axios.post('https://api.shreedigimarketing.in/api/addtestimonial', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Testimonial added successfully!');
      console.log(response.data);
  
      // Clear the form by resetting the state
      setFormData({
        clientname: '',
        profession: '',
        testimonial: '',
        image: null,
      });
  
      // Optionally, clear the file input value manually (if required)
      document.getElementById('image').value = null;
    } catch (error) {
      alert('Failed to add testimonial');
      console.error(error);
    }
  };
  
  return (
    <div>
      <body className="bg-theme bg-theme1">
        <div id="pageloader-overlay" className="visible incoming">
          <div className="loader-wrapper-outer">
            <div className="loader-wrapper-inner">
              <div className="loader"></div>
            </div>
          </div>
        </div>

        <div id="wrapper">
          <div className="clearfix"></div>

          <div className="content-wrapper" style={{ marginLeft: '70vh' }}>
            <div className="container-fluid">
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title">Testimonial Form</div>
                      <hr />
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="clientname">Client Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="clientname"
                            placeholder="Enter Client Name"
                            value={formData.clientname}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="profession">Profession</label>
                          <input
                            type="text"
                            className="form-control"
                            id="profession"
                            placeholder="Enter Profession"
                            value={formData.profession}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="testimonial">Testimonial</label>
                          <textarea
                            className="form-control"
                            id="testimonial"
                            placeholder="Enter Testimonial"
                            rows="3"
                            value={formData.testimonial}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label htmlFor="image">Upload Image</label>
                          <input type="file" className="form-control" id="image" onChange={handleFileChange} />
                        </div>

                        <div className="form-group">
                          <button type="submit" className="btn btn-light px-4">
                            Add Testimonial
                          </button>
                       <a href='/viewtestimonials'>  <button type="button" className="btn btn-light px-4 m-2">
                            View Testimonial 
                          </button></a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay toggle-menu"></div>
            </div>
          </div>

          <a href="javaScript:void();" className="back-to-top">
            <i className="fa fa-angle-double-up"></i>
          </a>

          <footer className="footer">
            <div className="container">
              <a href="https://shreedigimarketing.in/">
                Copyright © 2024 Shree Digital Marketing Agency
              </a>
            </div>
          </footer>
        </div>
      </body>
    </div>
  );
}

export default Testinomial;
