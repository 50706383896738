import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from './images/logo (3).png';
import dg from './images/dg1.png';

function Register() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get("https://api.shreedigimarketing.in/api/currentuser", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("User data:", response.data);
          setUser(response.data); // Assuming the response contains user data
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);

  return (
    <div>
      <body className="bg-theme bg-theme1" style={{ height: "100vh" }}>
        <div id="pageloader-overlay" className="visible incoming">
          <div className="loader-wrapper-outer">
            <div className="loader-wrapper-inner">
              <div className="loader"></div>
            </div>
          </div>
        </div>

        <div id="wrapper">
          <div className="clearfix"></div>

          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="row mt-3">
                <div className="col-lg-4">
                  <div className="card profile-card-2">
                    <div className="card-img-block">
                      <img className="img-fluid" src={dg} alt="Card image cap" />
                    </div>
                    <div className="card-body pt-5">
                      <img src={logo} alt="profile-image" className="profile" />
                      <h5 className="card-title">Shree Digital Marketing Agency</h5>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="mb-3">User Profile</h5>
                      <div className="row">
                        {user ? (
                          <div className="col-md-6">
                            <h6>Name</h6>
                            <p>{user.username || "N/A"}</p>
                            <h6>Email</h6>
                            <p>{user.email || "N/A"}</p>
                            <h6>Password</h6>
                            <p>**********</p>
                          </div>
                        ) : (
                          <p>Loading user data...</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a href="javaScript:void();" className="back-to-top">
            <i className="fa fa-angle-double-up"></i>
          </a>
        </div>
      </body>
    </div>
  );
}

export default Register;
