import React from 'react';
import ReactDOM from 'react-dom/client'; // 'react-dom' ki jagah 'react-dom/client' import karo
import { Provider } from 'react-redux'; // Redux Provider ko import karo
import { store } from './Redux/store'; // Aapke Redux store ko import karo
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')); // createRoot ka use karo

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
