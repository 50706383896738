import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setAuth } from "./Redux/actions";
import { useNavigate } from "react-router-dom";
import logo from './images/logo (3).png'

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for UX
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Disable button during API call
    setError(""); // Reset error before a new attempt

    try {
      const response = await axios.post("https://api.shreedigimarketing.in/api/login", {
        email,
        password,
      });

      const { token } = response.data;

      // Store token and set auth state
      localStorage.setItem("token", token);
      dispatch(setAuth(true));
      navigate("/home", { replace: true });
    } catch (err) {
      console.error("Error occurred during login:", err);
      if (err.response && err.response.data) {
        setError(err.response.data.message);
      } else {
        setError("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false); // Re-enable the button
    }
  };

  return (
    <div>
      <body className="bg-theme bg-theme1" style={{height:'auto'}}>
        <div id="pageloader-overlay" className="visible incoming">
          <div className="loader-wrapper-outer">
            <div className="loader-wrapper-inner">
              <div className="loader"></div>
            </div>
          </div>
        </div>

        <div id="wrapper">
          <div className="clearfix"></div>

          <div className="content-wrapper" style={{ marginLeft: '70vh' }}>
            <div className="container-fluid">
              <div className="row mt-3">
                <div className="col-lg-6">
				<div className="card card-authentication1 mx-auto my-5">
        <div className="card-body">
          <div className="card-content p-2">
            <div className="logo">
              <img src={logo} alt="logo icon" style={{height:'20vh',marginLeft:'15vh'}}/>
            </div>
            <div className="card-title text-uppercase text-center py-3">Sign In</div>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  className="form-control input-shadow"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>label</label>
                <input
                  type="password"
                  value={password}
                  className="form-control input-shadow"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-light btn-block"
                disabled={loading} // Disable button when loading
              >
                {loading ? "Signing In..." : "Sign In"}
              </button>
              {error && <p className="text-warning mt-3">{error}</p>}
            </form>
          </div>
        </div>
      </div>
                </div>
              </div>
              <div className="overlay toggle-menu"></div>
            </div>
          </div>

          <a href="javaScript:void();" className="back-to-top">
            <i className="fa fa-angle-double-up"></i>
          </a>

          

        
        </div>
      </body>
    </div>
  );
}

export default Login;
