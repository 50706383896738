import axios from 'axios';
import React, { useEffect, useState } from 'react'

function Home() {
  const [enquiries, setEnquiries] = useState([]);
  const [counts, setCounts] = useState({
    EnquiryCount: 0,
    testimonialCount: 0,
  });

  // Fetch counts from API
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [testimonials,enquiry,] = await Promise.all([
          axios.get('https://api.shreedigimarketing.in/api/testimonials/count'),
          axios.get('https://api.shreedigimarketing.in/api/enquiry/count'),
        ]);

        setCounts({
          EnquiryCount: enquiry.data.count,
          testimonialCount: testimonials.data.count,
         
        });
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);


  // Fetch enquiries on component mount
  useEffect(() => {
    axios.get('https://api.shreedigimarketing.in/api/allenquiries')  // Make sure the correct API endpoint is used
      .then(response => {
        setEnquiries(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the enquiries!', error);
      });
  }, []);

  // Handle deleting an enquiry
  const deleteEnquiry = (id) => {
    // Axios request in React or JavaScript
axios.post('https://api.shreedigimarketing.in/api/deleteenquiry', {
  eid:id  // Send the enquiry ID in the body
})
  .then(response => {
      console.log(response.data);
  })
  .catch(error => {
      console.error(error.response.data);
  });

  };

  return (
    <div>
  

  <body className="bg-theme bg-theme1" style={{height:'100vh'}}>
 <div id="wrapper">
<div className="clearfix"></div>
	
  <div className="content-wrapper">
    <div className="container-fluid">
      <div className="card mt-3">
    <div className="card-content">
  <div className="row row-group m-0">
  <div className="col-12 col-lg-6 col-xl-3 border-light">
  <div className="card-body">
    <h5 className="text-white mb-0">
      {counts.testimonialCount || 0} <span className="float-right"><i className="fa fa-shopping-cart"></i></span>
    </h5>
    <div className="progress my-3" style={{ height: '3px' }}>
      <div className="progress-bar" style={{ width: '55%' }}></div>
    </div>
    <p className="mb-0 text-white small-font">
      Total Testimonials <span className="float-right">+4.2% <i className="zmdi zmdi-long-arrow-up"></i></span>
    </p>
  </div>
</div>
    <div className="col-12 col-lg-6 col-xl-3 border-light">
      <div className="card-body">
        <h5 className="text-white mb-0">
          8323 <span className="float-right"><i className="fa fa-usd"></i></span>
        </h5>
        <div className="progress my-3" style={{ height: '3px' }}>
          <div className="progress-bar" style={{ width: '55%' }}></div>
        </div>
        <p className="mb-0 text-white small-font">
          Total Revenue <span className="float-right">+1.2% <i className="zmdi zmdi-long-arrow-up"></i></span>
        </p>
      </div>
    </div>
    <div className="col-12 col-lg-6 col-xl-3 border-light">
      <div className="card-body">
        <h5 className="text-white mb-0">
          6200 <span className="float-right"><i className="fa fa-eye"></i></span>
        </h5>
        <div className="progress my-3" style={{ height: '3px' }}>
          <div className="progress-bar" style={{ width: '55%' }}></div>
        </div>
        <p className="mb-0 text-white small-font">
          Visitors <span className="float-right">+5.2% <i className="zmdi zmdi-long-arrow-up"></i></span>
        </p>
      </div>
    </div>
    <div className="col-12 col-lg-6 col-xl-3 border-light">
  <div className="card-body">
    <h5 className="text-white mb-0">
      {counts.EnquiryCount || 0} <span className="float-right"><i className="fa fa-envira"></i></span>
    </h5>
    <div className="progress my-3" style={{ height: '3px' }}>
      <div className="progress-bar" style={{ width: '55%' }}></div>
    </div>
    <p className="mb-0 text-white small-font">
      Total Enquiries <span className="float-right">+2.2% <i className="zmdi zmdi-long-arrow-up"></i></span>
    </p>
  </div>
</div>
  </div>
</div>

 </div>  
	  
	
	
 <div  >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  Recent Enquiries
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush table-borderless">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enquiries.map(enquiry => (
                        <tr key={enquiry.id}>
                          <td>{enquiry.name}</td>
                          <td>{enquiry.email}</td>
                          <td>{enquiry.subject}</td>
                          <td>{enquiry.message}</td>
                          <td>{new Date(enquiry.createdAt).toLocaleDateString()}</td>
                          <td>
                            <button onClick={() => deleteEnquiry(enquiry.id)} className="btn btn-danger">
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
    
    </div>
    <a href="javaScript:void();" className="back-to-top"><i className="fa fa-angle-double-up"></i> </a>
   
	<footer className="footer">
      <div className="container">
        <div className="text-center">
            <a href='https://shreedigimarketing.in/'>
          Copyright © 2024 Shree Digital Marketing Agency
          </a>
        </div>
      </div>
    </footer>
	
   <div className="right-sidebar">
    <div className="switcher-icon">
      <i className="zmdi zmdi-settings zmdi-hc-spin"></i>
    </div>
    <div className="right-sidebar-content">

      <p className="mb-0">Gaussion Texture</p>
      <hr/>
      
      <ul className="switcher">
        <li id="theme1"></li>
        <li id="theme2"></li>
        <li id="theme3"></li>
        <li id="theme4"></li>
        <li id="theme5"></li>
        <li id="theme6"></li>
      </ul>

      <p className="mb-0">Gradient Background</p>
      <hr/>
      
      <ul className="switcher">
        <li id="theme7"></li>
        <li id="theme8"></li>
        <li id="theme9"></li>
        <li id="theme10"></li>
        <li id="theme11"></li>
        <li id="theme12"></li>
		<li id="theme13"></li>
        <li id="theme14"></li>
        <li id="theme15"></li>
      </ul>
      
     </div>
   </div> 
  </div>   
</body>
    </div>
  )
}

export default Home